
import { Vue, Component, PropSync, Prop } from 'vue-property-decorator';
import { GlobalMixin } from '@/mixins/global-mixin';

@Component
export default class ProfilePasswordChange extends GlobalMixin {
  private verifyFlag = false;
  private password = '';
  private passwordConfirm = '';

  async created(): Promise<void> {
    const { query } = this.$route;
    const { token } = query;
    if (token && typeof token === 'string') {
      await this.verify(token);
    }
  }

  private async verify(token: string) {
    const { data, result } = await this.axios({
      url: `/user/token-confirm`,
      method: 'POST',
      data: {
        token
      }
    });
    this.verifyFlag = !!result;
    if (!this.verifyFlag) await this.$router.replace({ path: '/my-page/profile' });
  }
  async passwordChange(): Promise<void> {
    try {
      const { data, result, message } = await this.axios({
        url: `/user/password-change`,
        method: 'POST',
        data: {
          password: this.password,
          passwordConfirm: this.passwordConfirm,
        }
      });
       if (!result && message) {
         this.validationMessages(message);
       } else {
         console.log(data);
         await this.$router.replace({ path: '/my-page/profile' });
       }
    } catch (e) {
      console.log(e);
    }
  }
}
