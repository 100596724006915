
import { Vue, Component } from 'vue-property-decorator';
import PasswordConfirm from '@/pages/my-page/profile/password-confirm.vue';
import ProfileEdit from '@/pages/my-page/profile/profile-edit.vue';
import PasswordChange from '@/pages/my-page/profile/password-change.vue';
@Component({
  components: { PasswordChange, ProfileEdit, PasswordConfirm },
})
export default class MyProfile extends Vue {
  private firstName = '';
  private lastName = '';
  private company = '';
  async created() {
    await this.load();
  }
  async load() {
    this.loading = true;
    const { data, result } = await this.axios({
      url: `/user/${this.accessToken}`,
      method: 'get',
    });
    if (result) {
      console.log(data);
      const { userGroup, firstName, lastName } = data as { userGroup: string; firstName: string; lastName: string };
      this.company = userGroup;
      this.firstName = firstName;
      this.lastName = lastName;
    }
    // await new Promise((resolve) => setTimeout(resolve, 1000));
    this.loading = false;
  }
  get accessToken() {
    return this.$store.getters.accessToken;
  }
}
