
import { Component } from 'vue-property-decorator';
import { GlobalMixin } from '@/mixins/global-mixin';

enum NEXT_PAGE {
  PASSWORD_CHANGE = 'password-change',
  EDIT_PROFILE = 'edit-profile',
}

@Component({})
export default class ProfilePasswordChange extends GlobalMixin {
  private password = '';
  private next: string | null = null;

  created(): void {
    const { query } = this.$route;
    const { type } = query;
    if (type && typeof type === 'string') this.next = type as string;
  }

  async passwordConfirm(): Promise<void> {
    this.proc(true);
    try {
      const params = {
        userId: this.$store.getters.userID,
        userPwd: this.password
      };
      const { result, message, data } = await this.axios({
        url: `/user/password-confirm`,
        method: 'POST',
        data: params
      });
      const { confirm_token } = data || {} as { confirm_token?: string };
      if (!result) {
        this.validationMessages(message);
      } else if (confirm_token) {
        console.log(data);
        if (this.next === NEXT_PAGE.EDIT_PROFILE) {
          await this.$router.replace({ path: '/my-page/profile/edit', query: { token: confirm_token } });
        } else if (this.next === NEXT_PAGE.PASSWORD_CHANGE) {
          await this.$router.replace({ path: '/my-page/profile/password-change', query: { token: confirm_token } });
        }
      }
    } catch (e) {
      console.log(e);
    }
    this.proc(false);
  }
}
